import React from 'react';
import {
  Box,
  Input
} from '@chakra-ui/react'
import { connectSearchBox, connectHits } from 'react-instantsearch-dom';

const SearchBox = ({ currentRefinement, refine }) => (
  <Box
    as='form'
    noValidate
    action=""
    role="search"
  >
    <Input
      placeholder='Le meilleur bateau du monde'
      type="search"
      p='3rem 5rem'
      fontSize='32px'
      borderBottom='solid 1px'
      borderBottomColor='blue.brand'
      value={currentRefinement}
      onChange={(event) => refine(event.currentTarget.value)}
    />
  </Box>
);
export const CustomSearchBox = connectSearchBox(SearchBox);
// print out first and last characters around search term
const getSnippet = (excerpt, match) => {
  const index = excerpt.indexOf(match);
  return excerpt.substring(index - 50, index + 50);
};
// only display Hits when user types in SearchBox
const Hits = ({ hits }) => (
  <ul className="style">
    {hits.map((hit) => (
      <li key={hit.title}>
        <a href={hit.fields.slug}>
          {hit.title}
          <p>{`...${getSnippet(hit.excerpt, hit._highlightResult.title.matchedWords[0])}...`}
          </p>
        </a>
      </li>
    ))}
  </ul>
);
export const CustomHits = connectHits(Hits);