import React, { useState } from 'react'

import {
    Box,
    Form,
    Input
} from '@chakra-ui/react'
import algoliasearch from 'algoliasearch/lite';
import {
    InstantSearch,
    // SearchBox,
    Hits,
    Configure
} from 'react-instantsearch-dom';
import { CustomHits, CustomSearchBox } from '../components/Search/Custom';

const searchClient = algoliasearch(
    'RS6RR22SXT',
    '0f4d8b1fea9f0aee16128e41b21030f6'
);

const Brand = (node) => {
    return (
        <Box>
            {node.node.name ? node.node.name.fr : null}
        </Box>
    )
}
const Model = (node) => {
    console.log('node', node)
    return (
        <Box>
            {node.node.name ? node.node.name.fr : null}
        </Box>
    )
}
const Ad = (node) => {
    return (
        <Box>
            {node.node.name ? node.node.name.fr : null}
        </Box>
    )
}

const result = (data) => {
    // console.log(data)
    if (data.hit.sys.contentType.sys.id === 'model') {
        return <Model node={data.hit.fields} />
    }
    if (data.hit.sys.contentType.sys.id === 'brand') {
        return <Brand node={data.hit.fields} />
    }
    if (data.hit.sys.contentType.sys.id === 'ad') {
        return <Ad node={data.hit.fields} />
    }
    // if (data.hit.sys.contentType.sys.id === 'ad') {
    //     return <Ad node={ data } />
    // }
    return (
        <Box>Return</Box>

    )

}
const SearchTpl = () => {
    const [query, setQuery] = useState(false)

    const
        [isToggledOn, setToggle] = useState(false),
        [hasInput, setInput] = useState(false),
        [refresh, setRefresh] = useState(false)

    return (
        <Box>
            <InstantSearch
                searchClient={searchClient}
                indexName="PROD_Wanaboat"
                refresh={refresh}
            >
                <Configure hitsPerPage={20} />
                <CustomSearchBox />
                {/* <SearchBox
                    className="searchbox"
                    class="ais-SearchBox-input"
                    submit={<></>}
                    reset={<></>}
                    translations={{
                        placeholder: 'Search Postman Docs',
                    }}
                    onKeyUp={(event) => {
                        setInput(event.currentTarget.value !== '')
                    }}
                /> */}

                <Box>

                </Box>
                <Hits
                    // Optional parameters
                    hitComponent={result}
                />

            </InstantSearch>
        </Box >
    )
}

export default SearchTpl